import cartMiniFooter from './directives/сartMiniFooter.js';
import './styles/cart.scss';
const pathToCartMiniSidebarTemplate = WEBPACK_BASE_URL + 'scripts/_partials/cart/templates/cart-mini-sidebar.html';
const MODULE_NAME = 'cart';
angular.module(MODULE_NAME).config(["$provide", function ($provide) {
  $provide.decorator("cartMiniListDirective", ["$delegate", function ($delegate) {
    const directive = $delegate[0];
    const path = WEBPACK_BASE_URL + 'scripts/_partials/cart/templates/cart-mini.html';
    directive.templateUrl = path;
    return $delegate;
  }]);
}]).directive("cartMiniFooter", cartMiniFooter).run(["cartService", "cartConfig", "sidebarsContainerService", "$translate", (cartService, cartConfig, sidebarsContainerService, $translate) => {
  cartService.addCallback(cartConfig.callbackNames.add, () => {
    sidebarsContainerService.open({
      contentId: "cart-mini",
      templateUrl: pathToCartMiniSidebarTemplate,
      title: $translate.instant('Js.Cart.Cart'),
      hideFooter: true,
      sidebarClass: "sidebar--cart"
    });
  }, "cartMiniList");
  cartService.addCallback(cartConfig.callbackNames.open, () => {
    sidebarsContainerService.open({
      contentId: "cart-mini",
      templateUrl: pathToCartMiniSidebarTemplate,
      title: $translate.instant('Js.Cart.Cart'),
      hideFooter: true,
      sidebarClass: "sidebar--cart"
    });
  });
}]);
export default MODULE_NAME;