//import '../../../bundle_config/common.js';
import appDependency from '../../../scripts/appDependency.js';
import stickyElementModule from '../scripts/_common/stickyElement/stickyElement.module.js'; //import setCssCustomPropsModule from '../scripts/_common/setCssCustomProps/setCssCustomProps.module.js';

appDependency.addItem(stickyElementModule); //appDependency.addItem(setCssCustomPropsModule);

import '../scripts/_common/dropdown/dropdown.module.js';
import '../scripts/_common/autocompleter/autocompleter.module.js';
import '../scripts/_common/tabs/tabs.module.js';
import '../scripts/managers/managers.module.js';
import '../scripts/_common/zoomer/zoomer.module.js';
import '../scripts/_common/modal/modal.module.js';
import '../scripts/_common/spinbox/spinbox.module.js';
import '../scripts/_partials/cart/cart.module.js';
import '../scripts/_partials/catalog-filter/catalogFilter.module.js';
import '../scripts/_partials/shipping/shipping.module.js';
import '../scripts/_partials/payment/payment.module.js';
import '../styles/partials/bonus-card.scss';
import '../scripts/_common/carousel/carousel.module.js';
import '../scripts/_partials/colors-viewer/colorsViewer.module.js';
import '../scripts/_partials/order/order.module.js';
import '../scripts/_partials/product-view/productView.module.js';
import '../scripts/_partials/reviews/reviews.module.js';
import '../scripts/_partials/subscribe/subscribe.module.js';
import '../scripts/preorder/preorder.module.js';
import '../scripts/registration/registration.module.js';
import '../styles/views/checkout.scss';
import '../styles/views/product.scss';
import '../styles/views/news.scss';
import '../styles/views/brands.scss';
import '../styles/partials/pagenumberer.scss';
import '../styles/partials/login.scss';
import '../styles/partials/breads.scss';
import '../styles/partials/footer.scss';
import '../styles/partials/toolbar-top.scss';
import '../styles/common/inputs.scss';
import '../styles//common/buttons.scss';
import '../styles/common/validation.scss';
import '../styles/common/forms.scss';
import '../styles/common/custom-input.scss';
import '../styles/common/sidebar.scss';
import '../styles/common/buttons.scss';
import '../styles/common/forms.scss';
import '../styles/common/animations.scss';
import '../styles/common/personal-menu.scss';
import '../scripts/_partials/zone/zones.scss';
import '../styles/partials/header.scss';
import '../styles/partials/menu-dropdown.scss';
import '../styles/partials/menu-general.scss'; //import '../styles/partials/toolbar-top.scss';

import '../styles/partials/fixed-header.scss';
import '../styles/partials/recently-viewed-block.scss';
import '../styles/partials/socials.scss';
import '../styles/partials/price.scss';
import '../fonts/fonts.css';
import '../styles/general.scss';
import '../styles/views/compareproducts.scss';
import '../styles/views/feedback.scss';
import '../styles/views/home.scss';
import '../scripts/_common/additional-text/additionalText.module.js';
import '../styles/partials/popular-categories.scss';
import '../styles/partials/advantages.scss';
import '../styles/views/myAccount.scss';
import '../styles/views/wishlist.scss';