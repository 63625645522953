CartMiniFooter.$inject = ["$timeout", "$window", "cartService", "cartConfig"];

/* @ngInject */
export default function CartMiniFooter($timeout, $window, cartService, cartConfig) {
  return {
    require: "^cartMini",
    restrict: "A",
    scope: true,
    controller: 'CartMiniCtrl',
    controllerAs: '$ctrl',
    bindToController: true,
    templateUrl: 'templates/Modern_Promo/scripts/_partials/cart/templates/cartMiniFooter.html',
    link: {
      post: function (scope, element, attrs, ctrls) {
        const calcHeightFooter = () => {
          $timeout(() => {
            const style = element[0].currentStyle || $window.getComputedStyle(element[0]),
                  height = element[0].offsetHeight,
                  margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom),
                  padding = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom),
                  border = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
            const totalHeight = (height + margin - padding + border || 0) + "px";
            const root = document.querySelector(':root');
            root.style.setProperty('--cartFooterHeight', totalHeight);
          }, 0);
        };

        calcHeightFooter();
        cartService.addCallback(cartConfig.callbackNames.update, calcHeightFooter, "cartMiniFooter");
        scope.$on('$destroy', function () {
          cartService.removeCallback(cartConfig.callbackNames.update, "cartMiniFooter");
        });
      }
    }
  };
}