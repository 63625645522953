StickyElement.$inject = ["stickyEventsService"];

/* @ngInject */
export default function StickyElement(stickyEventsService) {
  return {
    restrict: 'A',
    scope: true,
    link: function (scope, element, attrs, ctrl) {
      element[0].classList.add("sticky-element");
      element[0].style[attrs.position || "top"] = "".concat(attrs.offset || -1, "px");
      var observer = new IntersectionObserver(_ref => {
        let [e] = _ref;

        if (e.intersectionRatio < 1) {
          e.target.classList.add("is-pinned"); //element[0].style.top = '0';
        } else {
          e.target.classList.remove("is-pinned"); //element[0].style.top = '-400px';
        }
      }, {
        threshold: [1]
      });
      observer.observe(element[0]); //stickyEventsService.createHeaderObserver();
      //stickyEventsService.addElementToObserver(element[0]);
    }
  };
}