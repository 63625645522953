import reviewsModule from '../../../../../scripts/_partials/reviews/reviews.module.js';
import './styles/reviews.scss';
angular.module(reviewsModule).config(["$provide", function ($provide) {
  $provide.decorator("reviewsFormDirective", ["$delegate", function ($delegate) {
    const directive = $delegate[0];
    const path = WEBPACK_BASE_URL + 'scripts/_partials/reviews/templates/reviewForm.html';
    directive.templateUrl = path;
    return $delegate;
  }]);
}]);
export default reviewsModule;