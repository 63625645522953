export default class StickyEventsService {
  constructor() {}

  createHeaderObserver() {
    if (this.headerIntersectionObserver) {
      return;
    }

    return this.headerIntersectionObserver = new IntersectionObserver(function (entries) {
      // no intersection 
      if (entries[0].intersectionRatio === 0) {
        console.log('1');
        entries[0].target.classList.add("nav-container-sticky");
      } // fully intersects 
      else if (entries[0].intersectionRatio === 1) {
        entries[0].target.classList.remove("nav-container-sticky");
        console.log('2');
      }
    }, {
      threshold: [0, 1]
    });
  }

  createFooterObserver() {
    return new IntersectionObserver(_ref => {
      let [e] = _ref;
      return e.target.classList.toggle('isSticky', e.intersectionRatio < 1);
    }, {
      threshold: [1]
    });
  }

  addElementToObserver(el) {
    this.headerIntersectionObserver.observe(el);
  }

  removeElementToObserver(el) {
    this.headerIntersectionObserver.unobserve(el);
  }

}